*,
*::before,
*::after {
    box-sizing: border-box;
}

::selection {
    background-color: #29cefc;
    color: white;
}

html {
    overflow: hidden;
    height: 100vh;
    @media only screen and (max-width: 1000px) {
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

body {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    font-family: "ethnocentric", sans-serif;
    background: linear-gradient(to bottom, rgba(61, 205, 240, 0.75), rgba(28, 96, 112, 0.40));
    margin: 0;
    padding: 0;

    @media only screen and (max-width: 1000px) {
        background: linear-gradient(to bottom, rgba(61, 205, 240, 0.75), rgba(28, 96, 112, 0.75));
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

@media (min-width: 1024px) {
    * {
        cursor: none !important;
    }

    .cursor {
        position: fixed;
        width: 32px;
        height: 64px;
        transform: translate(-50%, -50%);
        pointer-events: none;
        z-index: 10;
        background-image: url('images/cursor.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;
    }

    .hover-cursor {
        width: 32px;
        height: 64px;
        padding-top: 32px;
        background-image: url('images/cursor-click.png');
    }
}

@media (max-width: 1023px) {

    /* Reset to default cursor behavior on smaller screens */
    .cursor {
        display: none;
    }
}

// Subheading Styles
.loltron-subheading {
    position: absolute;
    color: white;
    font-size: 32px;
    text-shadow: -2px 0 4.1px rgba(255, 146, 179, .27), 2px 0 4px rgba(223, 255, 248, .59), 0 0 39.1px hsla(0, 0%, 100%, .29);
    bottom: 15px;
    left: 15px;

    span {
        display: block;
        font-size: 14px;

        @media only screen and (max-width: 1250px) {
            font-size: 13px;
        }

        @media only screen and (max-width: 1000px) {
            font-size: 11px;
        }

        @media only screen and (max-width: 600px) {
            font-size: 9px;
        }

        @media only screen and (max-width: 340px) {
            font-size: 8px;
        }

        @media only screen and (max-width: 320px) {
            font-size: 7px;
        }
    }

    @media only screen and (max-width: 1000px) {
        position: relative;
        margin-top: 20px;
    }

    @media only screen and (min-width: 3840px) {
        transform:scale(2.5);
        left: 385px;
        bottom: 115px;
    }
}

// Background Styles
.background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, rgba(0, 255, 255, 0.2), rgba(0, 128, 255, 0.2) 33%, rgba(33, 33, 33, 0.2) 0%, rgba(33, 33, 33, 0.2));
    background-size: auto 3px;
    background-repeat: repeat-y;
    z-index: 11;
    pointer-events: none;
    opacity: .75;
}

// Glow Container Styles
.glow-container {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: -10;
        right: 0;
        width: 110%;
        height: 25vh;
        background: linear-gradient(to bottom, rgba(61, 205, 240, 0.6), rgba(64, 0, 255, 0));
        z-index: -1;
    }
}

// Logo Styles
.logo {
    width: 100%;
    position: relative;
    top: -10px;
    left: -10px;
    pointer-events: none;
    user-select: none;

    @media only screen and (max-width: 1000px) {
        top: 0;
        left: 0;
    }
}

// Character Styles
.character {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    max-width: 650px;
    pointer-events: none;
    user-select: none;

    @media only screen and (min-width: 1000px) and (min-height: 880px) {
        max-width: 750px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 800px) {
        max-width: 600px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 750px) {
        max-width: 550px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 700px) {
        max-width: 500px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 650px) {
        max-width: 450px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 570px) {
        max-width: 400px;
    }

    @media only screen and (min-width: 1440px) and (max-height: 1200px) {
        right: 150px;
    }

    @media only screen and (min-width: 2000px) and (max-height: 1200px) {
        right: 250px;
    }

    @media only screen and (max-width: 1250px) {
        max-width: 700px;
    }

    @media only screen and (max-width: 1100px) {
        max-width: 650px;
    }

    @media only screen and (max-width: 1050px) {
        max-width: 600px;
    }

    @media all and (min-device-width: 820px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) and (orientation:landscape) {
        max-width: 625px;
    }

    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: landscape) {
        max-width: 450px;
    }

    @media only screen and (min-width: 3840px) {
        max-width: 1600px;
    }
        

    @media only screen and (max-width: 1000px) {
        display: none !important;
    }
}

.character2 {
    display: block;
    position: absolute;
    bottom: -150px;
    right: -330px;
    z-index: 2;
    width: 100%;
    max-width: 750px;
    pointer-events: none;
    user-select: none;

    @media only screen and (min-width: 1000px) and (min-height: 880px) {
        max-width: 850px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 800px) {
        max-width: 700px; 
        right: -270px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 750px) {
        max-width: 650px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 700px) {
        max-width: 600px;
        right: -250px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 650px) {
        max-width: 550px;
    }

    @media only screen and (min-width: 1000px) and (max-height: 570px) {
        max-width: 500px;
        right: -220px;
    }

    @media only screen and (min-width: 1440px) and (max-height: 1200px) {
        right: -170px;
    }

    @media only screen and (min-width: 2000px) and (max-height: 1200px) {
        right: -100px;
    }

    @media only screen and (max-width: 1250px) {
        max-width: 800px;
    }

    @media only screen and (max-width: 1100px) {
        max-width: 750px;
    }

    @media only screen and (max-width: 1050px) {
        max-width: 700px;
    }

    @media all and (min-device-width: 820px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) and (orientation:landscape) {
        max-width: 725px;
        right: -310px;
    }

    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: landscape) {
        max-width: 550px;
        right: -220px;
    }

    @media only screen and (min-width: 3840px) {
        max-width: 1700px;
        right: -600px;
        bottom: -250px;
    }

    @media only screen and (max-width: 1000px) {
        display: none !important;
    }
}

.character-mobile {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    max-width: 650px;
    pointer-events: none;
    user-select: none;

    @media only screen and (min-width: 1280px) {
        max-width: 750px;
    }

    @media only screen and (min-width: 1200px) and (max-height: 850px) {
        max-width: 600px;
    }

    @media only screen and (max-width: 1150px) {
        max-width: 600px;
    }

    @media only screen and (max-width: 1080px) {
        max-width: 550px;
    }

    @media only screen and (max-width: 1000px) {
        position: relative;
        max-width: 100%;
        width: 100%;
        margin-top: -20px;
        margin-left: 0;
        z-index: 1;
        float: right;
        display: block;
    }
}

.btn {
    position: relative;
    display: inline-block;
    padding: .5rem .75rem;
    background: #5aafc6;
    color: #fff;
    border: 3px solid #fff;
    font-size: 12px;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    letter-spacing: 0.3ch;
    font-weight: 600;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    transform: scale(1);
    transition: all .3s linear;
    &:hover {
        transform: scale(1.05);
        transition: all .3s linear;
    }
    &::before {
        content: '';
        border: 2px solid #5aafc6;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
        border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
        pointer-events: none;
    }
    &.btn-outline {
        background:transparent;
        // border-color: #5aafc6;
        color: #5aafc6;
    }
}

// Content Container Styles
.content-container {
    position: relative;
    width: 550px;
    padding: 1rem 1.5rem;
    display: inline-block;
    border: 3px solid #fff;
    font-size: 13px;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    letter-spacing: 0.3ch;
    background: #ffffff;
    color: #5aafc6;
    font-weight: 600;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    margin-top: 12vh;
    margin-left: 16vh;
    transform: scale(1.20);

    @media only screen and (max-width: 1600px) {
        margin-left: 13vh;
    }

    @media only screen and (max-width: 1550px) {
        margin-left: 9vh;
    }

    @media only screen and (max-width: 1450px) {
        margin-top: 9vh;
        margin-left: 5vh;
        transform: scale(1);
    }
    
    @media only screen and (max-width: 1250px) {
        margin-top: 2vh;
        margin-left: 2vh;
        transform: scale(1);
    }

    @media only screen and (max-width: 1000px) {
        margin-left: 15px;
        width: 100%;
        margin-left: 0;
        margin-top: -100px;
        z-index: 1;
    }

    @media only screen and (min-width: 1000px) and (max-height: 800px) {
        width: 600px;
        transform: scale(1);
        margin-left:9vh;
        margin-top: 9vh;
    }

    @media only screen and (min-width: 1024px) and (max-height: 615px) {
        width: 600px;
        transform: scale(1);
        margin-left: 4vh;
        margin-top: 4vh;
    }

    @media only screen and (min-width: 1024px) and (max-height: 600px) {
        width: 800px;
        transform: scale(.8);
        margin-left: 0vh;
        margin-top: 0vh;
    }

    @media only screen and (min-width: 1400px) and (max-height: 600px) {
        width: 800px;
        transform: scale(1);
        margin-left:4vh;
        margin-top: 4vh;
    }

    @media only screen and (min-device-width: 834px) and (max-device-width: 1024px) and (min-device-height: 1112px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        width: 800px;
        margin-left: 4vh;
        margin-top: 4vh;
    }

    @media only screen and (min-width: 3840px) {
        transform:scale(3);
        margin-top:25vh;
        margin-left:32vh;
    }

    &::before {
        content: '';
        border: 2px solid #fff;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
        border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
        pointer-events: none;
    }

    strong {
        font-size: 15px;
        margin-bottom: 15px;
        display: block;
        font-weight: 700;
        font-family: "ethnocentric", sans-serif;
    }

    ul {
        display: flex;
        list-style-type: none;
        margin-left: -5px;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 0;
        z-index: 2;
        margin-top: 10px;
        margin-bottom: 0;

        @media only screen and (max-width: 450px) {
            max-width: 400px;
            flex-wrap: wrap;
        }
    }

    li {
        color: #5aafc6;
        font-family: "ethnocentric", sans-serif;

        a {
            color: #5aafc6;
            font-size: 10px;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 15px;
            display: block;
            transform: scale(1);
            transition: all .3s linear;

            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }

            @media only screen and (max-width: 450px) {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 12px;
            }

            @media only screen and (max-width: 375px) {
                // margin-top: 5px;
                // margin-bottom: 5px;
            }
        }

        a:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition: all .3s linear;
        }
    }
}

// Music Styles
.music {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    bottom: 65px;
    z-index: 3;

    @media only screen and (max-width: 1000px) {
        position: relative;
        top: -113px;
        bottom: inherit;
        float: right;
    }

    @media only screen and (min-width: 3840px) {
        transform:scale(3);
        bottom: 315px;
        right: 85px;
    }

    button {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 1;
        animation: pulse 2s infinite;

        &:hover {
            cursor: pointer;
        }
    }

    img {
        width: 80px;
        height: 80px;

        @media only screen and (max-width: 600px) {
            width: 60px;
            height: 60px;
        }
    }

    span {
        font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        color: white;
        font-size: 17px;
        text-shadow: -2px 0 4.1px rgba(255, 146, 179, .27), 2px 0 4px rgba(223, 255, 248, .59), 0 0 39.1px hsla(0, 0%, 100%, .29);
        margin-top: -3px;

        @media only screen and (max-width: 1000px) {
            font-size: 15px;
        }
    }
}

@keyframes pulse {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.075);
    }
}

.disclaimer {
    position: absolute;
    z-index: 3;
    font-size: 9px;
    color: white;
    max-width: 260px;
    text-shadow: -2px 0 4.1px rgba(255, 146, 179, .27), 2px 0 4px rgba(223, 255, 248, .59), 0 0 39.1px hsla(0, 0%, 100%, .29);
    bottom: 15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: 15px;
    text-align: right;

    @media only screen and (max-width: 1000px) {
        position: relative;
        text-align: left;
        margin-top: 20px;
        margin-left: 15px;
        margin-right: 0;
    }
    
    @media only screen and (min-width: 3840px) {
        transform:scale(3);
        margin-right: 300px;
        bottom: 100px;
    }
}

#theater {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: black;
    background-image: linear-gradient(to bottom, rgba(61, 205, 240, 0.85), rgba(28, 96, 112, 0.40));
    background-size: cover;
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    will-change: opacity, visibility; // Optimizing for smooth transitions
    transition: opacity 0.5s ease-in-out; // Ensure transition is smooth for opacity changes

    &.open {
        opacity: 1;
        visibility: visible;
    }

    #theater-video,
    video,
    iframe {
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        pointer-events: none;

        @media only screen and (max-width: 775px) {
            object-fit: contain;
            position: relative;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.90) 100%);
        pointer-events: none;
        z-index: 4;
    }

    #theater-logo {
        img {
            display: block;
            position: absolute;
            max-width: 200px;
            z-index: 10;
            margin-top: 25px;
            margin-left: 25px;

            @media only screen and (max-width: 1000px) {
                max-width: 150px;
            }
        }
    }

    #theater-sound {
        position: absolute;
        z-index: 10;
        padding-top: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        padding-left: 25px;
        min-width:145px;
        bottom: 0;
        right: 0;
        font-family: "ethnocentric", sans-serif;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        text-align: right;
        &.muted {
            animation: pulse 2s infinite;
        }

        @media only screen and (max-width: 1000px) {
            font-size: 12px;
        }

        @media only screen and (width: 360px) and (height: 670px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
            bottom: 55px;
        }
    }

    #theater-exit {
        position: absolute;
        z-index: 10;
        padding-left: 25px;
        padding-bottom: 25px;
        bottom: 0;
        font-family: "ethnocentric", sans-serif;
        font-weight: 700;
        color: #fff;
        text-decoration: none;

        @media only screen and (max-width: 1000px) {
            font-size: 12px;
        }

        @media only screen and (width: 360px) and (height: 670px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
            bottom: 55px;
        }
    }
}